// external imports
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import Select from "react-select"

// helpers
import { up, addResponsivity, theme } from "../lib/styles"
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
} from "../lib/helpers"
import { applyLang, applyLangSingle } from "../lib/intl"
import injectIntl from "../lib/intl/injectIntl"

// components
import T from "../lib/intl/T"
import {
  SEO,
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Listing,
  ContactFormSection,
  WaveImage,
  ResponsiveImage,
  Button,
} from "../components"
import ChangeItem from "../components/ChangeItem"
import ChevronIcon from "../components/ChevronIcon"

// assets
// import chevronPath from '../data/images/icon-chevron-down.svg'

// custom styled components
const StyledSelect = styled(Select)`
  ${({ width }) => css`
    max-width: 365px;
  `}
`

// react-select styles
const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "#646A71",
    fontSize: "16px",
    // background: state.isSelected ? 'rgba(244, 31, 82, 0.8)' : 'white',
    background: state.isSelected ? theme.colors.green : "white",
    color: state.isSelected ? "white" : "#646A71",
    padding: "8px 16px",

    ":hover": {
      background: theme.colors.green,
      color: "white",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#646A71",
    fontSize: "16px",
  }),
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #d8d8d8",
    borderRadius: state.menuIsOpen ? "8px 8px 0 0" : "8px",
    // borderBottom: state.menuIsOpen && '1px solid transparent',
    transition: "0.0s",
    borderColor: "#d8d8d8",
    boxShadow: "none",
    padding: "0 0 0 6px",

    ":hover": {
      borderColor: "#d8d8d8",
      boxShadow: "none",
      // borderBottom: state.menuIsOpen && '1px solid transparent',
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: !state.hasValue && "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "0 0 8px 8px",
    marginTop: 0,
    border: "1px solid #d8d8d8",
    boxShadow: "none",
    borderTop: "none",
    overflow: "hidden",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    // display: 'none',
    color: "#646A71",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.5px",
    lineHeight: "14px",
    // textTransform: 'uppercase',
  }),
  menuList: base => ({
    ...base,
    // minHeight: 'fit-content',
    maxHeight: 400,
  }),
}

class Changes extends React.Component {
  state = {
    selectedProcedure: null,
    limit: 10,
  }

  render() {
    const {
      data,
      location: { pathname },
      intl: { language, t },
    } = this.props

    const {
      changes = [],
      introImage,
      introImage2Blocks,
      introImage1Block,
      procedures,
    } = extractNodesFromDataApplyLangs(data, language)

    const { selectedProcedure, limit } = this.state

    const INTRO_BP = 777

    const procedureOptions = procedures
      .filter(p =>
        (p.tags || []).some(tag =>
          (changes || []).some(c =>
            (c.tags || []).some(tag2 => tag2._id === tag._id)
          )
        )
      )
      .map(p => ({
        label: p.name,
        value: p._id,
      }))

    let shownChanges = [...changes]
    if (selectedProcedure) {
      const tags =
        (procedures.find(p => p._id === selectedProcedure.value) || {}).tags ||
        []
      shownChanges = shownChanges.filter(c =>
        (c.tags || []).some(t => tags.some(tt => tt._id === t._id))
      )
    }
    const limitedChanges = shownChanges.slice(0, limit)

    return (
      <>
        <SEO
          title={t("changes - meta title")}
          description={t("changes - meta desc")}
          pathname={pathname}
        />
        <Gap.Top />
        <Row responsive justifyContent="stretch" bp={INTRO_BP}>
          <Col maxWidth="390px" grow="10">
            <Title.PageTitle>
              <T>Proměny našich klientů</T>
            </Title.PageTitle>
            <Gap gap="24px" />
            <Text.Intro>
              <T>
                Podívejte se na proměny našich klientů. Najdete u nich videa,
                rozhovory s lékaři, fotografie před a po ošetření a dojmy a
                recenze klientů. Jak jsou spokojeni s výsledkem, co říkají na
                naše doktory a jak se jim líbili prostory Formé Clinic?
              </T>
            </Text.Intro>
          </Col>
          <Gap.Fluid min="10px" max="90px" showAfter={INTRO_BP} />
          <Gap gap="24px" hideAfter={INTRO_BP} />

          <Col grow="10" maxWidth="655px">
            <ResponsiveImage
              hideAfter={INTRO_BP}
              image={introImage.image.gatsbyImageData}
            />
            <ResponsiveImage
              showBetween={[INTRO_BP, 900]}
              image={introImage1Block.image.gatsbyImageData}
            />
            <ResponsiveImage
              showBetween={[901, 1100]}
              image={introImage2Blocks.image.gatsbyImageData}
            />
            <ResponsiveImage showAfter={1101} image={introImage.image.gatsbyImageData} />
          </Col>
        </Row>
        <Gap gap="16px" mobileGap="40px" />
        <Col maxWidth="820px" width="100%" alignSelf="center">
          <StyledSelect
            value={selectedProcedure}
            onChange={selectedProcedure => this.setState({ selectedProcedure })}
            options={procedureOptions}
            // width="365px"
            placeholder={t("Všechny zákroky")}
            styles={selectStyles}
            isClearable
            // menuIsOpen
            components={{ DropdownIndicator: ChevronIcon.Select }}
          />
          <Gap gap="48px" mobileGap="24px" />
          {limitedChanges.map(c => (
            <ChangeItem key={c._id} change={c} />
          ))}
        </Col>
        {/* ends with 80/32px padding */}
        {limit < limitedChanges.length + 1 && (
          <Col alignItems="center" width="100%">
            <Button.Dashed
              onClick={() => {
                this.setState(state => ({
                  limit: state.limit + 10,
                }))
              }}
            >
              <T>další proměny</T>
            </Button.Dashed>
          </Col>
        )}
        <Gap gap="64px" mobileGap="8px" />
        <ContactFormSection />
      </>
    )
  }
}

export default injectIntl(Changes)

export const pageQuery = graphql`
  query ($language: String!) {
    changes: allPosts(
      sort: { fields: when, order: DESC }
      filter: {
        state: { eq: "published" }
        isChange: { eq: true }
        lang: { eq: $language }
      }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    procedures: allProcedures(sort: { fields: sortOrder, order: ASC }) {
      edges {
        node {
          ...ProcedureFragment
        }
      }
    }
    introImage: file(relativePath: { eq: "images/changes-intro-image.png" }) {
      image: childImageSharp {
        gatsbyImageData(
          width: 777
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    introImage2Blocks: file(
      relativePath: { eq: "images/changes-intro-image-2-blocks.png" }
    ) {
      image: childImageSharp {
        gatsbyImageData(
          width: 575
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
    introImage1Block: file(
      relativePath: { eq: "images/changes-intro-image-1-block.png" }
    ) {
      image: childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`
