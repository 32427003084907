import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap, RoundedImage, Link, WaveImage } from "./"

class ChangeItem extends Component {
  render() {
    const { change } = this.props
    const to = `${Link.BLOG}/${change.slug}`

    return (
      <Col maxWidth="820px" width="100%" alignItems="flex-start">
        <WaveImage desktop />
        <WaveImage small mobile />
        <Gap gap="24px" mobileGap="16px" />
        <Link to={to}>
          <Title.MediumTitle
            mobileFontSize="28px"
            mobileLineHeight="36px"
            as="h2"
          >
            {change.title}
          </Title.MediumTitle>
        </Link>
        <Gap gap="0px" mobileGap="4px" />
        <Text maxWidth="555px">{change.changeSubtitle}</Text>
        <Gap gap="16px" mobileGap="8px" />
        <Link to={to} maxWidth="820px" width="100%">
          <RoundedImage image={change.postImage.filename?.main.gatsbyImageData} />
        </Link>
        <Gap gap="80px" mobileGap="32px" />
      </Col>
    )
  }
}

ChangeItem.propTypes = {}

export default ChangeItem
